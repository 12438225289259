import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import PlanningVideo from "../../images/product/Planning a session.mp4"
import AdminVideo from "../../images/product/Adding Notes in admin portal FINISHED.mp4"
import SessionVideo from "../../images/product/Leaving the session map and exiting classroom FINISHED.mp4"
import YoutubeVideo from "../../images/product/YT Engagement.mp4"
import AdminPic from "../../images/product/LAPTOP_MOCKUP_TRANSPARENT.png"
import ActivityPic from "../../images/product/Screen Shot 2020-09-03 at 9.16.55 am.png"
import MapPic from "../../images/product/Fullscreen Background.png"

import { Container, Section } from "../global"

const Fun = () => {

  const handleSubmit = event => {
    event.preventDefault()
  }

  return (
      <Container >
       <Flex>

        <VideoTag preload='auto' autoPlay muted loop poster={MapPic}>
          <source src={YoutubeVideo} type="video/mp4"/>
          your browser is not supported! 
        </VideoTag>

        <HeaderTextGroup>
          <h1>
          A better way to deliver care
          </h1>
          <h2>
          Unlike zoom, delivering care in STAi is fun, easy, and engaging. 
        And it's more than a video call — you can play multiplayer activities, document clinical notes, 
        watch youtube videos - all in one place without ever sharing a screen or giving up remote control.
        </h2>
          <FormSubtitle>
          {/* <FormSubtitleLink to="/#providers"><HeaderButton aria-label="Click Here To See All Features">SEE ALL FEATURESs</HeaderButton></FormSubtitleLink>
            {/* <FormSubtitleLink to="/">Learn more about STAi Care</FormSubtitleLink> */}
          </FormSubtitle> 
        </HeaderTextGroup>
        </Flex>

       <Flex>
        

        <HeaderTextGroup>
          <h1>
          Plan your next session in just minutes
          </h1>
          <h2>
          STAi streamlined session planning allows you to plan and deliver comprehensive online speech therapy services, video chat, multiplayer activities, clinical documentation, scheduling all in one place. Less time scrambling and more time with your clients</h2>
          <FormSubtitle>
            <FormSubtitleLink to="/#providers">Learn how to streamline care STAi</FormSubtitleLink>
          </FormSubtitle>
        </HeaderTextGroup>
        <VideoTag autoPlay muted loop poster={AdminPic}>
          <source src={PlanningVideo} type="video/mp4"/>
          your browser is not supported! 
        </VideoTag>
        </Flex>
      </Container>
  )
}

export default Fun

const SectionTitle = styled.h1`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const VideoTag = styled.video`
  // height: 100%;
  // margin-left: 30%;
  width: 90%;
  border-radius: 10px;
  // object-fit: cover;
  // clip-path: polygon(13% 0, 100% 0, 100% 100%, 0% 100%);
`

const SectionSubtitle = styled.h2`
  color: ${props => props.theme.color.primary};
  ${props => props.theme.font_size.regular}
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const HeaderWrapper = styled.header`
  background-color: #43A5FF;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 70px;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const FormSubtitle = styled.span`
  ${props => props.theme.font_size.xxsmall}
`

const FormSubtitleLink = styled(Link)`
  color: ${props => props.theme.color.secondary};
  padding-bottom: 1px;
  margin-left: 8px;
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.color.secondary};
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  // color: ${props => props.theme.color.primary};
  letter-spacing: 1px;
  height: 30px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  // background: ${props => props.theme.color.primary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 800px;
  @media (max-width: ${props => props.theme.screen.lg}) {
    width: 500px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`

